<template>
  <h1>Não encontrado</h1>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style></style>
