<template>
  <div>
    <div class="ml-2 text-h4">Minhas Playlists</div>
    <div v-if="playlists && playlists.length < 1">
      <no-item></no-item>
    </div>
    <div v-else>
      <playlists :items="playlists" :loggedUser="loggedUser" />
    </div>
    <create-playlist :loggedUser="loggedUser"></create-playlist>
    <div v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getCollection } from '@/composables'
import { Playlists, CreatePlaylist } from '../components'
import { NoItem } from '@/components/layout/error'
import store from '@/store'
export default {
  name: 'UserPlaylist',

  components: {
    Playlists,
    NoItem,
    CreatePlaylist
  },

  setup() {
    const loggedUser = computed(() => store.state.auth.loggedUser)
    console.log(loggedUser)
    const { error, documents: playlists } = getCollection('playlists', [
      'userId',
      '==',
      loggedUser.value.uid
    ])
    return { playlists, loggedUser, error }
  }
}
</script>

<style></style>
