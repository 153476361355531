<template>
  <app-card
    flat
    height="86vh"
    class="d-flex flex-column align-center justify-center text--disabled"
  >
    <span class="text-h4" v-text="text"></span>
    <app-icon class="text--disabled" size="256" icon="album"> </app-icon>
  </app-card>
</template>

<script>
export default {
  name: 'NoItem',

  props: {
    text: {
      type: String,
      default: 'Não encontrado'
    }
  }
}
</script>

<style></style>
