<template>
  <v-list two-line>
    <v-list-item v-for="item in playlist.songs" :key="item.id">
      <v-list-item-content>
        <v-list-item-title v-text="item.title"></v-list-item-title>

        <v-list-item-subtitle v-text="item.artist"></v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action v-if="ownership">
        <delete-dialog
          :itemName="item.title"
          @handleDelete="handleDelete(item)"
        ></delete-dialog>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { DeleteDialog } from '@/components/layout/dialogs'
import { useDocument } from '@/composables'
export default {
  name: 'Songs',

  components: {
    DeleteDialog
  },

  props: {
    playlist: {
      type: Object,
      required: true
    },
    ownership: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const { updateDoc } = useDocument('playlists', props.playlist.id)
    const handleDelete = async (item) => {
      const songs = props.playlist.songs.filter((song) => song.id !== item.id)
      await updateDoc({ songs })
      console.log(songs)
    }

    return { handleDelete }
  }
}
</script>

<style></style>
