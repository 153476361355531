<template>
  <div>
    <app-btn-fab
      fixed
      icon="plus"
      @click="openCreatePlaylistSheet"
    ></app-btn-fab>
    <bottom-sheet
      size="400"
      :dialog="createPlaylistSheet"
      @close="closeCreatePlaylistSheet"
    >
      <app-form @submit.prevent="handleSubmit">
        <v-row no-gutters>
          <v-col cols="12">
            <app-text-field
              v-model="title"
              placeholder="Título"
            ></app-text-field>
          </v-col>
          <v-col cols="12">
            <app-textarea
              v-model="description"
              solo
              label="Descrição"
            ></app-textarea>
          </v-col>
          <v-col cols="12">
            <image-input @selectedFile="selectedFile" />
          </v-col>
          <v-col>
            <app-btn
              :disabled="formError"
              type="submit"
              lIcon="check"
              color="primary"
              block
              >Criar</app-btn
            >
          </v-col>
        </v-row>
      </app-form>
    </bottom-sheet>
  </div>
</template>
<script>
import utils from '@/utils/utils'
import { BottomSheet, useDialogs } from '@/components/layout/dialogs'
import { ImageInput } from '@/components/layout/forms'
import { useCollection, useStorage } from '@/composables'
import { fbTimestamp } from '@/plugins/firebase'
import store from '@/store'
import router from '@/router'
import { ref, computed } from '@vue/composition-api'
export default {
  name: 'CreatePlaylist',

  components: {
    BottomSheet,
    ImageInput
  },

  props: {
    loggedUser: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const randomInt = ref(utils.randomInt(0, 1000))
    const file = ref(null)
    const fileError = ref(null)

    const title = ref(`Test_${randomInt.value}`)
    const description = ref(
      `Description loren ipsun lorecva oiutr polid uews mjgrui y eur ${randomInt.value}`
    )
    const { filePath, url, uploadImage } = useStorage()
    const {
      dialog: createPlaylistSheet,
      handleOpen: openCreatePlaylistSheet,
      handleClose: closeCreatePlaylistSheet
    } = useDialogs()
    const { error, addDoc } = useCollection('playlists')

    const formError = computed(() => {
      return (
        title.value === '' ||
        description.value === '' ||
        file.value === undefined ||
        file.value === null
      )
    })

    const selectedFile = (item) => {
      console.log('Este é o arquivo', item.value)
      file.value = item.value
    }

    const handleSubmit = async () => {
      if (file.value) {
        store.dispatch('home/setOverlayLoading', true)
        await uploadImage(file.value, props.loggedUser, 'covers')
        const res = await addDoc({
          title: title.value,
          description: description.value,
          userId: props.loggedUser.uid,
          userName: props.loggedUser.displayName,
          coverUrl: url.value,
          filePath: filePath.value,
          songs: [],
          createdAt: fbTimestamp()
        })
        store.dispatch('home/setOverlayLoading', false)
        if (!error.value) {
          closeCreatePlaylistSheet()
          fileError.value = 'Playlist criada com sucesso'
          file.value = null
          title.value = ''
          description.value = ''
          console.log(res)
          router.push({ name: 'PlaylistDetails', params: { id: res.id } })
        }
        // console.log('image uploaded, url: ', url.value, filePath)
      } else {
        fileError.value = 'Imagem inválida'
        file.value = null
      }
    }

    /*  const types = ['image/png', 'image/jpeg']
    const handleChange = (e) => {
      const selected = e
      if (selected && types.includes(selected.type)) {
        if (selected.size > 5000000) {
          fileError.value = 'Imagem muito grande'
          file.value = null
        } else {
          file.value = selected
          fileError.value = null
        }
      } else {
        file.value = null
        fileError.value = 'Selecione uma imagem válida'
      }
      console.log(selected)
    } */

    return {
      title,
      description,
      fileError,
      formError,
      handleSubmit,
      selectedFile,
      //handleChange,
      createPlaylistSheet,
      closeCreatePlaylistSheet,
      openCreatePlaylistSheet
    }
  }
}
</script>
