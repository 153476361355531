<template>
  <div>
    <app-btn-fab
      color="primary"
      position="right-bottom"
      icon="plus"
      @click="openAddSongSheet"
    ></app-btn-fab>
    <bottom-sheet size="300" :dialog="addSongSheet" @close="closeAddSongSheet">
      <app-form @submit.prevent="handleSubmit">
        <app-text-field
          v-model="title"
          solo
          placeholder="Musica"
        ></app-text-field>
        <app-text-field
          v-model="artist"
          solo
          placeholder="Banda/Artista"
        ></app-text-field>
        <app-btn block type="submit" color="success" lIcon="plus"
          >Adicionar</app-btn
        >
      </app-form>
    </bottom-sheet>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useDocument } from '@/composables'
import { BottomSheet, useDialogs } from '@/components/layout/dialogs'
import utils from '@/utils/utils'
import store from '@/store'
export default {
  name: 'AddSongForm',

  components: {
    BottomSheet
  },

  props: {
    playlist: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const title = ref('')
    const artist = ref('')
    const {
      dialog: addSongSheet,
      handleClose: closeAddSongSheet,
      handleOpen: openAddSongSheet
    } = useDialogs()
    const { updateDoc } = useDocument('playlists', props.playlist.id)

    const handleSubmit = async () => {
      store.dispatch('home/setOverlayLoading', true)
      const newSong = {
        id: utils.uuid4(),
        title: title.value,
        artist: artist.value
      }
      await updateDoc({
        songs: [...props.playlist.songs, newSong]
      })
      store.dispatch('home/setOverlayLoading', false)
      title.value = ''
      artist.value = ''
      closeAddSongSheet()
    }

    return {
      title,
      artist,
      addSongSheet,
      closeAddSongSheet,
      openAddSongSheet,
      handleSubmit
    }
  }
}
</script>

<style></style>
