<template>
  <div>
    <create-playlist :loggedUser="loggedUser" />
    <v-list three-line>
      <v-list-item v-for="item in items" :key="item.id">
        <v-list-item-avatar>
          <v-img :src="item.coverUrl"></v-img>
        </v-list-item-avatar>

        <v-list-item-content @click="gotoDetails(item.id)">
          <v-list-item-title
            >{{ item.title }} de {{ item.userName || 'Sem Nome' }}
            <span class="grey--text text--lighten-1">{{
              item.songs && item.songs.length > 0
                ? `${item.songs.length} músicas`
                : ''
            }}</span></v-list-item-title
          >
          <v-list-item-subtitle
            v-html="item.description"
          ></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="loggedUser.uid === item.userId">
          <delete-dialog
            :itemName="item.title"
            @handleDelete="handleDelete(item)"
          />
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import CreatePlaylist from '@/modules/widgets/playlists/components/CreatePlaylist'
import { DeleteDialog } from '@/components/layout/dialogs'
import { useDocument, useStorage } from '@/composables'
import store from '@/store'
import utils from '@/utils/utils'
import router from '@/router'
export default {
  name: 'Playlists',

  components: {
    CreatePlaylist,
    DeleteDialog
  },

  props: {
    items: {
      type: Array,
      default: () => {}
    },
    loggedUser: {
      type: Object,
      required: true
    }
  },

  setup() {
    const handleDelete = async (item) => {
      store.dispatch('home/setOverlayLoading', true)
      const { deleteDoc } = useDocument('playlists', item.id)
      const { deleteImage } = useStorage()
      await utils.delay()
      await deleteImage(item.filePath)
      await deleteDoc()
      store.dispatch('home/setOverlayLoading', false)
    }

    const gotoDetails = (id) => {
      router.push({ name: 'PlaylistDetails', params: { id } })
    }

    return { handleDelete, gotoDetails }
  }
}
</script>

<style></style>
